import React from 'react';
import RedirectTo from "../../components/shared/RedirectTo/RedirectTo";
import {careersEnabled, getLink, getRoutes} from "../../components/shared/routes";
import OfferPage from "../../components/shared/OfferPage/OfferPage";
import PythonLogo from "../../images/Careers/deeplai-career-python-developer.svg";

const CareersPythonDevelopers = () => {
    const routes = getRoutes();

    if (!careersEnabled.list) {
        return <RedirectTo/>;
    }
    if (!careersEnabled.python) {
        return <RedirectTo url={getLink([routes.Careers])}/>;
    }

    return (
        <OfferPage
            image={PythonLogo}
            imageAlt={'deeplai career python developer'}
            name={routes.CareersPythonDeveloper.pageTitle}
            scopeOfDuties={<><p>software implementation by specification</p><p>active participation in
                the creation of the concept and design of software</p><p>cooperation with other teams -
                testers, designers, etc ...</p><p>cooperation in implementing and maintaining
                application with customers</p></>}
            ourRequirements={<><p>MID level, at least 1 year of commercial experience</p>
                <p>proficiency in Python, with experience in frameworks like FastAPI, Flask or Django</p>
                <p>knowledge of OOP, Design Patterns, asynchronous programming and advanced Python concepts</p>
                <p>knowledge of REST API interfaces development for SaaS platform (and Swagger tool)</p>
                <p>familiarity with API specifications such as OpenAPI, AsyncAPI, JSON Schema and entire lifecycle of
                    API design and documentation</p>
                <p>familiarity with serverless and microservice architecture</p>
                <p>basic knowledge of AWS</p>
            </>}
        />
    );
};

export default CareersPythonDevelopers;